@font-face {
    font-family: 'Cafe24Oneprettynight';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Cafe24Oneprettynight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.App {
    font-family: 'Cafe24Oneprettynight', sans-serif;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    span.titleName {
        background: linear-gradient(to top, #ffddf4 20%, transparent 20%);
    }

    section {
        padding: 3rem 0;

        span {
            font-size: 0.8rem;
        }

        h2, span {
            //color: #ce93d8;
            color: #666;
        }

        &#hero {
            position:relative;
            width:345px;
            margin:0 auto;
            img {
                position:absolute;
                left:0;
                width: 345px;
            }
        }

        &#introduce {
            .name {
                margin-left: 10px;
                background: linear-gradient(to top, #00FEFE 30%, transparent 30%);
            }
        }

        &#invitation {
            background-image: url("invitation.png");
            background-size: cover;

            h2, span {
                color: #fff;
            }
            p {
                margin: 0.5rem 0;
                color: #222222;
                font-weight: 600;
                span {
                    font-size: 1.6rem;
                }
            }
        }

        &#photo {
            h2, span {
                color: #fff;
            }
            div.wrapper {
                max-width: 345px;
                margin: 0 auto;
            }
            background-color: #333;
        }

        &#location {
            img {
                width: 90%;
                max-width: 345px;
            }
            p {
                font-size: 1.2rem;
            }
        }

        &#give {
            background-color: #fdf5f8;
            .account {
                padding: 0.5rem 1.5rem;
                margin: 1rem auto;
                text-align: justify;
                background: #fff;
                border-radius: 0.5rem;
                max-width: 320px;
                font-size: 20px;
            }

            .from-jerry {
                font-size: 1rem;
                font-weight: normal;
            }
        }

        h2 {
            margin-top: 0;
        }
    }
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        /*animation: App-logo-spin infinite 20s linear;*/
    }
}

button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

button {
    /* 생략 */
    margin: 0.3rem;
    padding: 1rem 0.6rem;
    font-family: 'Cafe24Oneprettynight', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: auto;
    border-radius: 2px;
    border: 2px solid #666;
    background-color: white;
    cursor: pointer;

    &:hover {
        background-color: #DC6D68;
    }
}

footer {
    font-size: 1rem;
    color: #333;
    padding: 1rem;
    span {
        color: red;
    }
}