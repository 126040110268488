/* FONTS AND TYPOGRAPHY */
.inline-calendar {
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    background: #ededed;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
  }

  h1 {
    font-size: 23px;
  }

  h2 {
    font-size: 17px;
  }

  p {
    font-size: 15px;
  }

  a {
    text-decoration: none;
    font-size: 15px;
  }

  h1,
  h2,
  p,
  a,
  span {
    color: #263859;
  }

  .icon {
    font-size: 25px;
  }

  /* ICON STYLING */
  .arrow-btn-container {
    position: relative;
  }

  .arrow-btn {
    position: absolute;
    display: block;
    width: 60px;
    height: 60px;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;

    .icon {
      display: block;
      transition: 150ms;
      font-size: 22px;
      border: 2px solid #263859;
      border-radius: 100%;
      line-height: 17px;
      width: 21px;
      margin: 20px auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .arrow-btn.left {
    border-top-left-radius: 5px;
  }

  .arrow-btn.right {
    border-top-right-radius: 5px;
    right: 0;
    top: 0;
  }

  .calendar-month {
    .arrow-btn:hover {
      background: #222a37;
    }

    .arrow-btn:hover {
      background: #16417e;
    }
  }

  /*  CALENDAR STYLINGS*/
  .container {
    margin: 0 auto;
    width: 300px;
    background: #fefefe;
    box-shadow: 0 0 10px -2px #ccc;

    h2.titular {
      line-height: 60px;
      font-family: sans-serif;
      text-align: justify;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      justify-content: space-between;
      padding: 0 1rem;
      display: flex;
      font-size: 1.2rem;
      span {
        font-size: 1.2rem;
        color: #222222;
      }
    }

    .calendar-month {
      height: 330px;

      .titular {
        font-family: "Cafe24Oneprettynight", sans-serif;
        color: #222;
        //background: #6b778dd8;
        background: #f3f3f3;
      }

      .arrow-btn:hover {
        background: #263859;

        .icon {
          color: #e0e0e0;
          border-color: #e0e0e0;
        }
      }
    }

    .calendar {
      margin: 10px auto;
      text-align: center;

      a {
        font-size: 17px;
      }

      td,
      th {
        width: 40px;
        transition: 100ms;
        height: 38px;
      }

      //td {
      //  &:hover {
      //    border: 1px solid #263859;
      //    background: #d3def3;
      //    border-radius: 50%;
      //  }
      //}

      .days-week {
        font-size: 20px;
        color: #222a37;
        font-family: "Cafe24Oneprettynight", sans-serif;
      }

      .selectedDay {
        a {
          color: #fafafa;
        }

        line-height: 34px;
        background: #d9605aec;
        border-radius: 50%;
        font-weight: 700;

        &:hover {
          background: #d9605aec;
          border-radius: 50%;
          border: none;
        }
      }

      .disabledDay {
        a {
          color: #8c8d8f;
        }

        &:hover {
          background: transparent;
          border-radius: 0;
          border: none;
        }
      }
    }
  }
}